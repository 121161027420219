@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700;800&family=Merriweather:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap);
*{ margin: 0px; padding: 0px; box-sizing: border-box; }
.e-link:hover {
 background: transparent;
}

.external-links { line-height: 1.65; color: #505050; font-size: 15px;}
.external-links p{ margin: 0px 0px 12px; }
.external-links li{margin-left: 16px;}
.highlighted-patch{margin-top: 16px}

.f15_b{font-weight: 700; color: #000}
.input-cntr {
}

.ap-login-field-container {
    padding: 10px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 2px solid #E0E0E0;
    border-radius: 19px;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.login-button {
    background: #FFBC36 0% 0% no-repeat padding-box !important;
    border-radius: 18px !important;
    height: 67px;
    font: normal normal bold 20px/36px tablet-gothic;
    letter-spacing: 0.6px !important;
    color: #252525 !important;
    text-transform: uppercase !important;
}
.errorMsg {
  /* height: 1.375em; */
}

.auth-form-cntr {
  margin: auto;
  max-width: 534px;
  padding: 0px 40px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.2); */
}

.desktop {
  max-width: 1366px;
  margin: 0 auto;
}

.desktop,
.desktop .auth-cntr {
  height: 100%;
}

.desktop .login-artifact {
  background: #fff9e5 0% 0% no-repeat padding-box;
}

.desktop .rl-btns {
  display: flex;
}

.desktop .rl-btns .RegistrationBtn,
.desktop .rl-btns .loginBtn {
  margin-top: 0 !important;
}

.desktop .rl-btns .login-link-cntr {
  align-items: flex-start !important;
  margin-left: 25px !important;
  justify-content: center;
}

.mobile .login-artifact img {
  height: 313px;
}

.errorMsg {
  color: #e6111b;
  font-size: 0.875rem;
  padding: 5px 0;
}


.text-decoration-none {
  text-decoration: none !important;
}

.color-black {
  color: black;
}

.margin-auto {
  margin: auto !important;
}

.text-align-center {
  text-align: center !important;
}

.color-brown {
  color: rgb(186, 31, 0);
}

.fw-bold {
  font-weight: bold;
}

.forgot-password-bottom {
  display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2% 0%;
}

.mt-15 {
  margin-top: 15px;
}
.session-event {
    font: normal normal normal 9px/18px tablet-gothic;
    letter-spacing: 0.18px;
    color: #323232;
    text-transform: uppercase;
    text-align: center;
}

.table-session tr th {
    padding: 5px;
}

.special-button:disabled, .special-button[disabled] {
    background-color: gray !important;
    color: white !important;
}

.session-event * {
    display: block;
    margin: auto;
}

.session-event img {
    width: 24px;
    height: 24px;
}

.sesion {
    font: normal normal bold 18px/18px tablet-gothic;
    color: #494949;
}

tr:first-child {
    background: #F2F2F2 0% 0% no-repeat padding-box;
}

.card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFBC36;
    border-radius: 14px;
    padding: 15px 15px 13px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-top: 12px;
    text-decoration: none;
    color: inherit;
}

.card:hover {
    background: rgb(255, 188, 54) 0% 0% no-repeat padding-box;
    cursor: pointer;
}

.card:hover .card-link {
    color: #FFFFFF;
}

.card-title {
    font: normal normal bold 20px/24px tablet-gothic
}

.card-description {
    font: normal normal 300 18px/20px tablet-gothic;
    color: #494949;
    margin-top: 5px;
}

.card-link {
    margin-top: 5px;
    color: rgb(255, 188, 54);
    text-transform: uppercase;
    font-weight: bold;
}

.card-score-text {
    font: normal normal 600 12px/24px tablet-gothic;
    letter-spacing: 0.24px;
    text-transform: uppercase
}

.card-score {
    font: normal normal normal 42px/22px tablet-gothic;
    letter-spacing: -2.1px;
    color: #2BB01C
}

.card:hover .card-score {
    color: #FFFFFF
}

.card-total-score {
    font: normal normal 300 12px/24px tablet-gothic;
    letter-spacing: 0.24px;
    color: #767676
}

.card-left-right {
    margin: auto 30px;
}

.table-session {
    border-collapse: collapse;
    width: 100%;
}

.table-session tr {
    height: 90px;
    border-bottom: 1px solid #F2F2F2;
}

.table-session td a {
    padding: 25px;
}

.table-session th:first-child, .table-session td:first-child {
    text-align: left;
    padding: 10px 10px 10px 20px;
}

.table-sessionname {
    font: normal normal normal 16px/22px tablet-gothic;
    color: #252525;
}

.table-sessionname * {
    display: block;
}

.table-sessionname .session-progress {
    font: normal normal normal 10px/12px tablet-gothic;
    color: #2BB01C;
    text-transform: uppercase;
    margin-top: 5px;
}

.table-session-number {
    font: normal normal normal 10px/18px tablet-gothic;
    color: #6E6E6E;
    text-transform: uppercase;
    display: block;
}

.table-session td:not(:first-child) {
    text-align: center;
}

.table-content td svg {
    vertical-align: middle;
}

.otp-model .box {
    background: #FFFFFF 0% 0% no-repeat padding-;
    box-shadow: 0px 2px 5px #00000021;
    border-radius: 40px;
    margin-top: 40px;
    margin-bottom: 80px;
    padding: 100px 20px 10px 30px;
}

.otp-model .box .otp-text {
    font: normal normal bold 14px/18px tablet-gothic;
    letter-spacing: 0.56px;
    color: #4E4E4E;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.otp-model .box .otp {
    text-align: center;
    width: 100%;
    margin-top: 20px;
}

.otp-model .box .otp input {
    font: normal normal normal 32px/32px tablet-gothic;
    letter-spacing: 0.32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F3F3F3;
    border-radius: 20px;
    width: 70px !important;
    height: 90px;
}

.special-button {
    background: #FFBC00 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 20px !important;
    text-align: center;
    /* width: 100%; */
    font: normal normal bold 14px/18px tablet-gothic !important;
    padding: 15px !important;
    /* margin-top: 15px !important; */
}

.points {
    color: #2BB01C;
    font: normal normal normal 12px/12px tablet-gothic;
}

.points :first-child {
    font: normal normal 300 28px/22px tablet-gothic;
    letter-spacing: -1.4px;
    display: block;
}

.custom-snackbar {
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 20px;
    padding-left: 20px;
}

.custom-snackbar .message {
    font: normal normal 600 16px/22px tablet-gothic;
    letter-spacing: 0.16px;
    margin: auto 0px auto 20px;
}

.custom-snackbar .icon {
    font: normal normal normal 12px/12px tablet-gothic;
    text-align: center;
    margin: auto;
}

.custom-snackbar .icon :first-child {
    font: normal normal 600 28px/22px tablet-gothic;
    display: block;
}

.bg-grey {
    background: #383838 0% 0% no-repeat padding-box !important;
}

.bg-white {
    background: white 0% 0% no-repeat padding-box !important;
}

.green {
    color: #2BB01C !important;
}

.white {
    color: white !important;
}

.bg-light-green {
    background: #D2FFCD 0% 0% no-repeat padding-box !important;
}

.assessment {
    margin: 30px 20px;
}

.assessment-heading {
    font: normal normal 600 22px/25px tablet-gothic;
}

.assessment .question-box {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    padding: 25px 40px;
    margin: 10px 0px;
}

.assessment .answer {
    font: normal normal normal 16px/24px tablet-gothic;
    color: #676767;
    margin: 30px 30px;
}

.assessment .answer label {
    padding: 10px 0px;
}

.assessment .answer label:not(:last-child) {
    border-bottom: 1px solid #DBDBDB;
}

.assessment .question-box span:first-child {
    font: normal normal 600 18px/20px tablet-gothic;
    display: block;
    color: #BA1F00;
    margin-bottom: 10px;
}

.assessment .question-box span {
    font: normal normal 600 17px/25px tablet-gothic;
    color: #494949;
}

.w-100 {
    width: 100% !important;
}

.assessment .exit {
    text-align: center;
}

.assessment .exit a {
    text-decoration: none;
    color: #BA1F00;
    padding: 10px;
}

.assessment .exit a:hover {
    color: white;
    background-color: #BA1F00;
}

.assessment .exit a svg {
    vertical-align: middle;
    margin-right: 10px;
}

.assessment-result {
    margin: 50px;
    text-align: center;
}

.assessment-result .heading, .session-rating .heading {
    font: normal normal 600 22px/25px tablet-gothic;
}

.assessment-result .score {
    font: normal normal 600 16px/22px tablet-gothic;
    /* color: #FFFFFF; */
    /* background-color: forestgreen; */
    margin: 20px auto;
}

.assessment-result .score div:first-child {
    font: normal normal 600 60px/0px tablet-gothic;
    display: inline;
}

.circle {
    font-size: 20px;
    /* color:#fff; */
    text-align: center;
    line-height: 0;
    border-radius: 50%;
    border-style: solid;
}

.outer {
    width: 160px;
    height: 160px;
    margin-top: 5px;
    background-color: #FFFFFF;
    /* border-color: forestgreen; */
    border-style: solid;
}

.inner {
    padding: 25% 0;
    width: 130px;
    height: 130px;
    position: relative;
    top: -7px;
    /* background-color: forestgreen; */
}

.assessment-result .assessment-text {
    font: normal normal 800 18px/24px tablet-gothic;
    letter-spacing: 0.72px;
    /* color: forestgreen; */
    text-transform: uppercase;
    margin-bottom: 20px;
}

.assessment-result .points {
    font: normal normal normal 18px/22px tablet-gothic;
    color: #4D4D4D;
}

.session-rating {
    margin: 40px 30px;
}

.session-rating .rating-holder {
    border-top: 1px solid #DBDBDB;
    margin: 10px 0px;
    padding: 20px 0px;
    font: normal normal normal 16px/22px tablet-gothic;
}

.mt-15px {
    margin-top: 15px !important;
}

body {
    position: relative;
    min-height: 100vh;
    height: 100vh;
    max-width: 450px;
    margin: auto;
}

@media only screen and (min-width: 450px) {
    body {
        /* margin-top: 1%; */
        /* box-shadow: 0px 3px 6px #00000029; */
        /* padding: 10px; */
        /* border-radius: 25px; */
    }
}


#root {
    height: 100%;
}

.session-button span:first-child, .session-button {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
}

/* .session-button .text { */

.text {
    color: #6E6E6E;
    font: normal normal normal 10px/12px tablet-gothic;
    text-transform: uppercase;
    margin-top: 5px;
}

.table-session img {
    width: 33px;
    height: 33px;
}

.pt-10 {
    padding-top: 10px !important;
}

.text-center {
    text-align: center;
}

.mt-70 {
    margin-top: 70px;
}

.mt-30 {
    margin-top: 30px;
}

.border-red {
    border: 2px solid #BA1F00 !important;
}

.br-18 {
    border-radius: 18px !important;
}

.hover-bg-red:hover {
    background: #E4001B 0% 0% no-repeat padding-box !important;
}

.hover-color-white:hover {
    color: white !important;
}

.color-black {
    color: black !important;
}

.hover-color-white:hover {
    color: white !important;
}

.copyright {
    font: normal normal normal 14px/26px tablet-gothic;
    letter-spacing: 0.28px;
    color: #9B9B9B;
}

.text-center {
    text-align: center;
}

.error {
    color: red;
}

.ml-5 {
    margin-left: 5px;
}

.ml-20 {
    margin-left: 20px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.text-right {
    text-align: right;
}

.tablet-gothic {
    font-family: tablet-gothic, sans-serif;
}

.w-50 {
    width: 50% !important;
}

.flex {
    display: flex;
}

.align-item-end {
    align-items: end;
}

.grey {
    color: grey;
}

.h-fit {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.v-center {
    margin-top: auto;
    margin-bottom: auto;
}

.w-25 {
    width: 25% !important;
}

.ml-10 {
    margin-left: 10% !important;
}

.mr-10 {
    margin-right: 10% !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.ls-18 {
    line-height: 18px !important;
}

.ls-22 {
    line-height: 22px !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.triangle {
    border-bottom: 15px solid #000;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    width: 0;
    height: 0;
}

.w-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.margin-auto {
    margin: auto;
}

.policy {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
}

.policy a {
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
}

.ml-auto {
    margin-left: auto !important
}

.mr-auto {
    margin-right: auto;
}

.mt-40px {
    margin-top: 40px;
}

.mb-40px {
    margin-bottom: 40px;
}

.red {
    color: red;
}

.greenyellow {
    color: #bde100;
}

.lawngreen {
    color: #67c507;
}

.forestgreen {
    color: #1bac00;
}

.sandybrown {
    color: #ffc043 !important;
}

.text-upper {
    text-transform: uppercase;
}

.border {
    border: 1px solid black !important;
}

.br-25px {
    border-radius: 25px !important;
}

.border-sandybrow {
    border-color: sandybrown !important;
}

.fw-bold {
    font-weight: bold !important;
}

.pl-30px {
    padding-left: 30px !important;
}

.pr-30px {
    padding-right: 30px !important;
}

.pt-10px {
    padding-top: 10px !important;
}

.pb-10px {
    padding-bottom: 10px !important;
}

/* padding: 10px 30px; */

/* margin-top: 15px; */

.indian-red {
    color: #c96551
}

.ml-10px {
    margin-left: 10px;
}

.f-right {
    float: right;
}

.pointer {
    cursor: pointer;
}

.lime-green {
    color: limegreen !important;
}

.pointer:hover {
    opacity: 0.5;
}

.flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.blinking {
    color: #BA1F00;
    /* margin-top: -15px; */
    margin-bottom: 10px;    
    -webkit-animation: blinkingText 1.5s infinite;    
            animation: blinkingText 1.5s infinite;
    text-transform: uppercase;
    font-size: 1rem;
}

@-webkit-keyframes blinkingText {
    from {
        opacity: 1;
        color : red;
        }
    to {
        opacity: .1;
        color : red;
        }
    /* 0% {
        opacity: 0;
    } */
    /* 50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    } */
}

@keyframes blinkingText {
    from {
        opacity: 1;
        color : red;
        }
    to {
        opacity: .1;
        color : red;
        }
    /* 0% {
        opacity: 0;
    } */
    /* 50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    } */
}
/* .slider-tooltip {	
    position: absolute;			
    text-align: center;			
    width: 100px;					
    height: 100px;					
    padding: 2px;
    overflow-y: scroll;					
    background: lightsteelblue;	
    border: 0px;		
    border-radius: 8px;				
} */

.myapp-slider-1 {
    position: absolute;
    width: 32%;
    top: 48%;
    right: 57%;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
}

.myapp-slider-2 {
    position: absolute;
    top: 34%;
    right: 47.5%;
    height: 20%;
    /* transform: rotate(90deg); */
}

.myapp-slider-3 {
    position: absolute;
    width: 32%;
    top: 48%;
    right: 14%;
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
}

.myapp-slider-4 {
    position: absolute;
    height: 18%;
    top: 57.5%;
    right: 25%;
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
}

.myapp-slider-5 {
    position: absolute;
    /* width: 20%; */
    height: 20%;
    top: 66%;
    right: 47.5%;
    /* transform: rotate(270deg); */
}

.myapp-slider-6 {
    position: absolute;
    width: 32%;
    top: 65%;
    right: 58%;
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
}

@media only screen and (max-width: 600px) {
    .MuiDialog-container .MuiDialog-paperFullScreen {
        display: flex;
    }

    .myapp-slider-5 {
        right: 45.6%;
    }

    .myapp-slider-1 {
        top: 46.5%;
        right: 57%;
    }

    .myapp-slider-3 {
        top: 46%;
        right: 14%;
    }

    .myapp-slider-6 {
        top: 63%;
        right: 60%;
    }

    .myapp-slider-4 {
        right: 22%;
    }

    .myapp-slider-2 {
        right: 45.6%;
    }
}

.takeaway-swipe .react-swipeable-view-container div {
    display: flex;
}

.takeaway-swipe .react-swipeable-view-container div *:first-child {
    margin: auto;
}

.takeaway-swipe .MuiMobileStepper-dots {
    margin: auto;
}
.bookcover {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
  }
  .bookcover-bordered {
    border-radius: 6px;
    border: 1px solid #cdcdcd;
  }
  .loading-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    color: #ffffff;
  }
  
  ._book-cntr {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    flex-grow: 1;
    padding-top: 48px;
  }
  
  ._book_meta_cntr {
    height: 100%;
    background-color: #333333;
  }
  
  .book-meta-cntr .page-cntr {
    background-color: #333333;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li.page-cntr{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .book-reader-menu {
    position: absolute;
    height: 50px;
    width: 100px;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #fff;
  }
  
  .page-numbers {
    position: absolute;
    height: 50px;
    width: 100px;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #aaaaaa;
  }
  
  #current-page {
    font-size: 1.8rem;
  }
  
  #number-of-pages {
    font-size: 1.2rem;
  }
  
  .react-pdf__Page__canvas{margin: auto;}

  .glide__arrow{
    border: none !important;
    padding: 9px 2px;
    border-radius: 0px;
  }
  .react-pdf__Page{display: flex; justify-content: center;}
  .glide__track{background-color: #333;}
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .glide__arrow--left{
      left: 0px;
     }
     
     .glide__arrow--right{
       right: 0px;
     }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    ._book-cntr {
      padding-top: 0px;
    }
  }
  
  .back-button {
   position: absolute; 
   top: 15px;
   left: 10px;
   background: none;
   border: none;
   font-size: 1rem;   
  }
.video-react, .video-react-video {
    width: 100vw !important;
    height: 100vh !important;
}
.flashpoint {
    height: 100%;
}

.flashpoint>div:nth-child(1) {
    padding: 0% 2%;
}

@media only screen and (max-width: 450px) {
    .flashpoint>div:nth-child(1) {
        padding: 0% 2%;
    }
}

.fp-bottom-nav {
    height: 85px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin: 1rem 0rem;
    border: 1px solid;
    border-radius: 16px;
}

.fp-unit-description {
    font-size: 14px;
    color: #575757;
    margin: 12px 0px;
}

.fp-view-response-button {
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: #000000 !important;
    padding: 3% !IMPORTANT;
    border-radius: 25px !important;
    bottom: 0%;
    width: 100%;
    right: 0%;
    justify-content: space-evenly !important;
    color: #FFFFFF !important;
    text-transform: none !important;
}

.fp-view-response-button_2 {
    background-color: darkgray !important;
    padding: 10px !important;
    border-radius: 25px !important;
    font-weight: bold !important;
    margin: 10px !important;
    position: absolute !important;
    bottom: 10px;
    /* width: 100%; */
    left: 0;
    text-transform: none !important;
}

.fp-quiz-back-button {
    background-color: darkgray !important;
    padding: 10px !important;
    border-radius: 25px !important;
    font-weight: bold !important;
    margin: 10px !important;
}

.fp-checklist-add-best-practice-button {
    color: white !important;
    background-color: green !important;
    padding: 10px !important;
    border-radius: 25px !important;
    font-weight: bold !important;
    margin: 10px !important;
}

.fp-unit-title {
    /* height: 15vh; */
    margin: 0px -2%;
    margin-bottom: 2%;
}

.fp-unit-title-unit-no {
    padding: 2% 4%;
    background-color: #1BAC00;
    color: white;
}

.fp-unit-completed {
    padding-top: 1rem;
    text-align: center;
}

/* .alternative-responses {
    height: 70%;
    overflow-y: auto;
} */

.alternative-responses .alternative-response:not(:last-child) {
    border-bottom: .1px solid #EEEEEE;
    padding-bottom: 3%;
    margin-bottom: 3%;
}

.alternative-response {
    position: relative;
    display: flex;
    margin: 2% 0%;
}

.response-body {
    margin-left: 5%;
}

.response-body .sender {
    font-weight: bold;
}

.fp-unit-quiz-container {
    height: 100%;
    background-color: #f3f3f3;
    padding: 5%;
}

.fp-unit-quiz-ans-container {
    width: 100%;
    margin: 5% 0% !important;
    background: white;
    padding: 5% !important;
    border-radius: 8px;
}

.fp-dashboard-tag {
    padding: 2%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 11px;
    text-align: center;
    margin: 1%;
    cursor: pointer;
}

.fp-dashboard-tag-active {
    background-color: #ba1f00;
    color: white;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
}

.fp-dashboard-search-icon {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    background-color: #FBD82A;
}

.fp-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: #FBD30D;
    border: 4px solid #FDE988;
    outline: 7px solid #FBD30D;
    margin: 5% auto;
    font-weight: bold;
    line-height: 1.2;
}

.fp-dashboard-score-card {
    position: relative;
    margin: 2%;
    padding: 3%;
    background-color: #FBD30D;
    border-radius: 36px;
    opacity: 0.88;
    text-align: center;
}

.fp-dashboard-score-unit-button {
    width: 100%;
    background-color: #000000 !important;
    padding: 5% !IMPORTANT;
    border-radius: 25px !important;
    font-weight: bold !important;
    justify-content: flex-end !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
}

.point-holder {
    /* display: grid;
    grid-template-columns: 1fr .1fr;
    padding: 1.2rem 2rem;
    border-bottom: 1px solid #EEEEEE;
    align-items: center;
     */
    display: grid;
    grid-template-columns: 1rem 1fr 0fr;
    padding: 1.2rem 2rem;
    /* margin-top: 1%; */
    border-bottom: 1px solid #EEEEEE;
    /* align-items: center; */
    font-weight: 500;
}

.point-holder .right-arrow-icon {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
    color: white;
    margin: auto;
}

.fp-unit-back-left-arrow-icon {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
    color: white;
}

.point-holder:hover {
    background-color: lightgray;
}

.position-relative {
    position: relative !important;
}

.point-description {
    margin-left: 15%;
}

.point-description ul {
    line-height: 26px;
    font-size: 20px;
}

.point-description li {
    margin: 1.5rem 0;
}

.fp-stepper-dots {
    width: .75rem;
    height: .75rem;
    background: white;
    margin: 0% 2%;
    border-radius: 50%;
}

.add-reponse-form {
    background-color: #FEE8AA;
    display: flex;
    flex-direction: column;
}

.point-description ul {
    margin-left: 15%;
}

.alternate-response-button {
    bottom: 15%;
    background-color: #FFF1C9 !important;
    color: black !important;
}

.fp-dashboard-results {
    text-align: center;
    font-weight: bold;
    color: darkgray;
    font-size: 1.5rem;
    font-style: italic;
}

.overflow-dot-2-lines {
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.fp-points-title {
    color: black;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #bdbdbd;
    text-align: center;
}

.fp-points-title-active {
    /* color: black; */
    padding: 1.5rem;
    border-bottom: 1px solid #bdbdbd;
    color: #a7a7a7;
    height: 16%;
}

.fp-points-description {
    background-color: rgb(255, 255, 255);
    border-radius: 27px;
    /* overflow-y: hidden; */
    /* margin-bottom: 2%; */
    height: 85%;
    min-height: 85%;
    /* overflow-y: auto; */
    position: relative;
}

.fp-card-view-fp-button {
    position: absolute !important;
    background-color: #000000 !important;
    padding: 3% !IMPORTANT;
    border-radius: 25px !important;
    bottom: 4%;
    width: 90%;
    text-transform: none !important;
    right: 4.5%;
    text-align: left;
    justify-content: space-evenly !important;
    color: #FFFFFF !important;
    line-height: 1.5 !important;
    letter-spacing: 0 !important;
}

.fp-alternative-response-button {
    /* display: block !important; */
    width: 100%;
    padding: 4% !important;
    border-radius: 24px !important;
    /* margin: 3% 0 !important; */
}

.fp-score-unit-vote {
    margin: 6%;
    padding: 10%;
    background-color: #FBD30D;
    border-radius: 26px;
    font-weight: bold;
}

.fp-icon {
    width: 2.5rem;
    margin-right: 3%;
}

.fp-unit-button {
    background-color: #000000 !important;
    padding: 3% !IMPORTANT;
    border-radius: 25px !important;
    margin: 2% 0% !important;
    text-transform: none !important;
    text-align: left;
    justify-content: space-evenly !important;
    color: #FFFFFF !important;
    line-height: 1.5 !important;
    letter-spacing: 0 !important;
    width: 100%;
}

.fp-unit-back-button {
    height: 10%;
    border-top: 1px solid rgb(189, 189, 189);
    padding: 6%;
    /* position: absolute; */
    /* bottom: 0px; */
    width: 100%;
}

.fp-alternate-solution-close-button {
    width: 2.25rem !important;
    height: 2.25rem !important;
    position: absolute;
    right: -10px;
    /* margin: 15px; */
    cursor: pointer;
    top: -45px;
    border-radius: 50%;
    background-color: #FBD30D;
}

.fp-unit-html-template {
    /* padding: 0.5% 17%; */
    /* margin: 6%; */
    padding: 6%;
}

.fp-unit-html-template li {
    margin: 7% 0%;
    /* padding: 0.5% 12%; */
}

.fp-unit-html-template ul {
    /* margin: 7% 0%; */
    /* padding: 0.5% 10%; */
    padding-left: 12%;
}

.fp-unit-floating-button {
    position: fixed !important;
    right: 4%;
    bottom: 1%;
}

.fp-fullscreen-img-close-button {
    position: absolute !important;
    /* margin-left: auto; */
    right: 1%;
}

.fp-view-all-fp-button {
    width: 96%;
    margin: 2% !important;
    background-color: #BA1F00 !important;
    padding: 7% !IMPORTANT;
    border-radius: 25px !important;
    justify-content: flex-end !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
}

.gray-background {
    background-color: #424242 !important;
}

.justify-content-center {
    justify-content: center !important;
}

.fp-alternate-avatar {
    width: 2.5rem!important;
    height: 2.5rem!important;
    font-size: 1rem !important;
    background-color: #c5c5c5 !important;
}

.fp-score-share-button {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid !important;
    border-radius: 24px;
    padding: 1rem !important;
}

.fp-score-share-button .share {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.walkthrough-button {
    width: 96%;
    margin: 2% !important;
    background-color: #BA1F00 !important;
    padding: 15px !IMPORTANT;
    border-radius: 25px !important;
    justify-content: flex-end !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
}

.fp-score-dashboard-share-container {
    position: absolute;
    bottom: 2%;
    width: 96%;
    /* width: 100%; */
}

.alternate-response-delete-icon {
    position: absolute;
    right: 0;
}

.alternate-response-load-more-button {
    width: 50%;
    background-color: white !important;
    margin-top: 3% !important;
    border: 1px solid darkgray !important;
    color: darkgray !important;
    text-transform: none !important;
}

.fp-point-display-none {
    display: none;
    /* padding-top: 15px; */
}

.alternate-response-number-loading {
    width: 1.1rem !important;
    height: 1.1rem !important;
    color: #fff !important;
    position: absolute !important;
    bottom: 8%;
}
.fp-success-story-textBox {
    display: flex;
    /* position: absolute; */
    /* width: 47%; */
    /* height: calc(50% - 34px); */
    /* height: 65vh; */
    height: 100%;
    flex-direction: column;
    /* top: 40%;
    right: 3%;
    line-height: 12px; */
    line-height: 1;
}


.UPTC_CS_Square-textEditorClass {
    border-bottom: 8px solid #e2e2e2;
    border-top: 2px solid #e2e2e2;
    height: calc(100% - 34px) !important;
}

.text-editor-WIAC_diff_in_the_lives {
    background-color: transparent;
    flex-grow: 1;
    overflow-y: hidden;
}

.CD-CNP-textbox-toolbar, .rdw-option-wrapper {
    background-color: transparent;
    border: 1px solid transparent !important;
}

.rdw-editor-toolbar-custom {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid transparent;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
.text-editor-wrapper {
    background-color: white;
    border-radius: 4px;
    padding: 8px;
    overflow-y: scroll;
    height: 150px;
    margin-bottom: 15px;
    flex-grow: 1;
}

.public-DraftEditor-content {
    overflow-wrap: anywhere !important;
}

.DraftEditor-root {
    height: unset !important;
}

.public-DraftStyleDefault-block {
    margin: 0.5em !important;
}
.d-flex {
    display: flex;
}

.ml-auto {
    margin-left: auto;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

.align-items-center {
    align-items: center;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.d-block {
    display: block;
}

.h-100 {
    height: 100%;
}

.h-auto {
    height: auto;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-decoration-none {
    text-decoration: none !important;
}

.overFlowYVisible {
    overflow-y: visible !important;
}
/* html, body, #root{height:100%} */

.overFlowYAuto {
    overflow-y: auto;
}

.textbox-scrollable-darkgray::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.textbox-scrollable-darkgray::-webkit-scrollbar-thumb {
    background-color: #989696;
    border-radius: 6px;
}

.textbox-scrollable-darkgray::-webkit-scrollbar-track {
    box-shadow: none;
    margin: 6px;
}

* {
    font-family: tablet-gothic !important;
    font-size: 1rem !important;
}

.redirect-page {
    margin: 10%
}

.redirect-page * {
    margin-bottom: 5%
}

.redirect-page *:not(:first-child) {
    margin-top: 5%
}
* {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 14px;
}

.flashpoint * {
    font-family: ff-tisa-sans-web-pro !important;
    font-size: 16px;
}

@media only screen and (min-width: 320px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.08rem;
    }
    .flashpoint .small-text {
        font-size: 0.75rem;
    }
    .flashpoint .small-text_2 {
        font-size: 0.9rem;
    }
    .flashpoint .large-text {
        font-size: 1.18rem;
    }
}

@media only screen and (min-width: 450px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.08rem;
    }
    .flashpoint .small-text {
        font-size: 0.75rem;
    }
    .flashpoint .small-text_2 {
        font-size: 0.9rem;
    }
    .flashpoint .large-text {
        font-size: 1.18rem;
    }
}

@media only screen and (min-width: 768px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.15rem;
    }
    .flashpoint .small-text {
        font-size: 0.85rem;
    }
    .flashpoint .small-text_2 {
        font-size: 1rem;
    }
    .flashpoint .large-text {
        font-size: 1.28rem;
    }
}

@media only screen and (min-width: 1024px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.3rem;
    }
    .flashpoint .small-text {
        font-size: 0.9rem;
    }
    .flashpoint .small-text_2 {
        font-size: 1.15rem;
    }
    .flashpoint .large-text {
        font-size: 1.48rem;
    }
}

.-hf {
    font-family: "Merriweather", serif;
}

.font-tablet-gothic {
    font-family: tablet-gothic !important;
}

.font-weight-bold {
    font-weight: bold;
}

.font-size-1_5-rem {
    font-size: 1.5rem;
}

.font-tisa-sans {
    font-family: ff-tisa-sans-web-pro, sans-serif;
}

.font-style-italic {
    font-style: italic;
}
.footer {
    padding: 30px;
    /* position: absolute; */
    /* bottom: 0; */
    /* width: 100%; */
}

.line-clamp {
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.line-clamp-desktop {
    -webkit-line-clamp: 4;
}

.line-clamp-mobile {
    -webkit-line-clamp: 6;
}

.e-link:hover {
    background: transparent;
}

.external-links p {
    line-height: 1.7;
}

